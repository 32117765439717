import Cookies from 'js-cookie';

document.addEventListener("DOMContentLoaded", function() {

  var body, cookie_notice_container, cookie_notice_removeable;

  cookie_notice_removeable = function() {
    var cookie_value;
    cookie_value = Cookies.get('cookie_notice_disabled');
    return cookie_value != null;
  };

  cookie_notice_container = document.querySelector('.cookie-notice');
  body = document.querySelector('body');

  if (cookie_notice_removeable()) {
    cookie_notice_container.parentNode.removeChild(cookie_notice_container);
    body.style.paddingBottom = '0';
  } else {
    cookie_notice_container.style.display = 'block';
  }

  cookie_notice_container.addEventListener('click', function() {
    Cookies.set('cookie_notice_disabled', true, {
      path: '/',
      expires: 365
    });
    body.style.paddingBottom = '0';
    return cookie_notice_container.parentNode.removeChild(cookie_notice_container);
  });
});
